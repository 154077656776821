<div class="main-wrapper {{ currentLanguage }}">
    @if(shouldShowWelcomeScreen) {
        <div class="welcome-screen">
            @if(mediaServer != '') {
                <div class="background-wrapper">
                    <img class="background" src="{{ mediaServer }}/zeta/welcome/background-splash.png" alt="welcome-screen">
                </div>
                <div class="logo-wrapper">
                    <img class="logo" src="{{ mediaServer }}/zeta/common/logo_wellbet.png" alt="">
                </div>
                <div class="text-wrapper">
                    <app-inline-preloader skip-hidration="true"></app-inline-preloader>
                    <span class="text-white">
                        {{ langx['LOADING_TEXT'] }}
                    </span>
                </div>
            }
        </div>
    } 
    
    @if(!shouldShowWelcomeScreen && isBrowser) {
        <router-outlet></router-outlet>
    }
</div>