import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LocalizedMessages } from '../shared/models/i18n.model';
import { CommonService } from '../shared/service/common/common.service';
import { CookieService } from '../shared/service/cookie/cookie.service';
import { HttpService } from '../shared/service/http/http.service';
import { PlatformService } from '../shared/service/platform/platform.service';
import { langxObj } from './main_langx';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent implements OnInit, OnDestroy {

  langx: LocalizedMessages = {};
  currentLanguage: string = '';

  shouldShowWelcomeScreen: boolean = false;
  isBrowser: boolean = false;

  mediaServer: string = '';
  destroy$ = new Subject<void>();

  constructor(
    private commonService: CommonService,
    private cookieService: CookieService,
    httpService: HttpService,
    platformService: PlatformService,
    location: Location
  ) {
    this.isBrowser = platformService.isBrowser();
    this.commonService.mediaServer$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      mediaServer => this.mediaServer = mediaServer
    );

    this.commonService.setLanguageFromUrl(location.path()).subscribe(language => {
      httpService.setLanguage(language);
    });

    this.commonService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentLanguage => {
        this.currentLanguage = currentLanguage;
        this.langx = langxObj[this.currentLanguage];
      });

    this.isBrowser = platformService.isBrowser();
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.shouldShowWelcomeScreen = !this.cookieService.getIsWelcomeScreenShown();
      setTimeout(() => {
        this.shouldShowWelcomeScreen = false;
        this.cookieService.setWelcomeScreenShown();
      }, 3000);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
