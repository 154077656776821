import { LocalizedMessagesBundle } from '../../../shared/models/i18n.model';

export const langxObj: LocalizedMessagesBundle = {
  'zh-cn': {
    'TITLE': '- 系统维护升级中 -',
    'GREETING': '尊敬的客户',
    'REASON_LABEL': ' 维护原因：',
    'TIPS_LINE': '期间将无法登录操作，给您带来不便，敬请见谅。',
    'CS_LINE': '如有任何疑问？请联系我们的客服（24/7 在线）',
    'CS_ICON_LABEL': '联系我们',
    'CS_BOTTOM_LINE': ' 我们将竭诚为您服务。'
  },
  'en-gb': {
    'TITLE': '- System maintenance -',
    'GREETING': 'Dear customers',
    'REASON_LABEL': 'Maintenance reason:',
    'TIPS_LINE': 'You will not be able to log in during this period. We apologize for the inconvenience.',
    'CS_LINE': 'Any questions? Please contact our customer service (24/7 online)',
    'CS_ICON_LABEL': 'Contact us',
    'CS_BOTTOM_LINE': 'We will serve you wholeheartedly.'
  }
}